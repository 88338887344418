export const environment = {
  production: false,
  panel: "mangoBackend_",
  domain: "magicexch.com",
  baseUrl: "https://api.magicexch.com",
  pollers: {
    oddsData: 600,
    listOddsData: 10000,
    scoresData: 1000,
  },
  noDataMsg: 'No Data Found',
  imgPrefix: '/mangoBackend_client',
  guestEnable: false,
  modalClass: 'bookModal',
  darkMode: false,
  userList: [
    { role_id: 1, id: 'admin', name: 'Admin', cIds: [], lIds: [] },
    { role_id: 2, id: 'super_master', name: 'Super Master', cIds: [1, 2], lIds: [1, 2] },
    { role_id: 3, id: 'master', name: 'Master', cIds: [1, 2], lIds: [1, 2] },
    { role_id: 4, id: 'client', name: 'Client', cIds: [1, 2, 3], lIds: [1, 2, 3] },
  ],
  hidePartenerShips: [9994],
  horseRaceJockeyImgPrefix: 'https://content-cache.cdnbf.net/feeds_images/Horses/SilkColours/',
  stakes: { "stake_name_1": "100", "stake_name_2": "5000", "stake_name_3": "500", "stake_name_4": "25000", "stake_name_5": "50000", "stake_name_6": "100000", "stake_name_7": "500000", "stake_name_8": "1000000", "stake_amount_1": 100, "stake_amount_2": 5000, "stake_amount_3": 500, "stake_amount_4": 25000, "stake_amount_5": 50000, "stake_amount_6": 100000, "stake_amount_7": 500000, "stake_amount_8": 1000000 },
  host: {
    domain: '',
    name: '',
    dName: '',
    ext: '',
    theme: '',
    email: '',
    themePath: [''],
    pusher: {
      key: '',
      cluster: ''
    },
    guestEnable: false,
  },
  hosts: [
    {
      domain: 'localhost',
      name: 'localhost',
      dName: 'LocalHost',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      themePath: ['assets/css/theme-files/theme-master.css'],
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      guestEnable: true,
    },
    {
      domain: 'parker777.io',
      name: 'parker777',
      dName: 'admin.parker777.io',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-master.css'],
      guestEnable: true,
    },
    {
      domain: 'saffron77exch.games',
      name: 'saffron77exch',
      dName: 'admin.saffron77exch.games',
      ext: '.games',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-saffron77exch.css'],
      guestEnable: true,
    },
    {
      domain: 'betfast.games',
      name: 'betfast',
      dName: 'admin.betfast.games',
      ext: '.games',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-betfast.css'],
      guestEnable: true,
    },
    {
      domain: 'sportsbar11.games',
      name: 'sportsbar11',
      dName: 'admin.sportsbar11.games',
      ext: '.games',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-sportsbar11.css'],
      guestEnable: true,
    },
    {
      domain: 'rolex9.games',
      name: 'rolex9',
      dName: 'admin.rolex9.games',
      ext: '.games',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-rolex9.css'],
      guestEnable: true,
    },
    {
      domain: 'tiger9exch.in',
      name: 'tiger9exch',
      dName: 'admin.tiger9exch.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-tiger9exch.css'],
      guestEnable: true,
    },
    {
      domain: 'starparker.in',
      name: 'starparker',
      dName: 'admin.starparker.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-starparker.css'],
      guestEnable: true,
    },
    {
      domain: 'play11.co',
      name: 'play11',
      dName: 'admin.play11.co',
      ext: '.co',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-play11.css'],
      guestEnable: true,
    },
    {
      domain: 'mahakal9.in',
      name: 'mahakal9',
      dName: 'admin.mahakal9.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-mahakal9.css'],
      guestEnable: true,
    },
    {
      domain: 'empireexch.in',
      name: 'empireexch',
      dName: 'admin.empireexch.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-empireexch.css'],
      guestEnable: true,
    },
    {
      domain: 'balajiexch99.in',
      name: 'balajiexch99',
      dName: 'admin.balajiexch99.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-balajiexch99.css'],
      guestEnable: true,
    },
    {
      domain: 'balajionline777.com',
      name: 'balajionline777',
      dName: 'admin.balajionline777.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-balajionline777.css'],
      guestEnable: true,
    },
    {
      domain: 'jannatexch999.in',
      name: 'jannatexch999',
      dName: 'admin.jannatexch999.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-jannatexch999.css'],
      guestEnable: true,
    },
    {
      domain: 'bigbet99exch.com',
      name: 'bigbet99exch',
      dName: 'admin.bigbet99exch.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-bigbet99exch.css'],
      guestEnable: true,
    },
    {
      domain: 'bigbee777.com',
      name: 'bigbee777',
      dName: 'admin.bigbee777.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-bigbee777.css'],
      guestEnable: true,
    },
    {
      domain: 'garuda555.co',
      name: 'garuda555',
      dName: 'admin.garuda555.co',
      ext: '.co',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-garuda555.css'],
      guestEnable: true,
    },
    {
      domain: 'fbcexch.com',
      name: 'fbcexch',
      dName: 'admin.fbcexch.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-fbc365exch.css'],
      guestEnable: true,
    },
    {
      domain: 'gamefair247.com',
      name: 'gamefair247',
      dName: 'admin.gamefair247.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-gamefair.css'],
      guestEnable: true,
    },
    {
      domain: 'playnwin555.com',
      name: 'playnwin555',
      dName: 'admin.playnwin555.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-playwin555.css'],
      guestEnable: true,
    },
    {
      domain: 'play365exch.in',
      name: 'play365exch',
      dName: 'admin.play365exch.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-play365exch.css'],
      guestEnable: true,
    },
    {
      domain: 'phoenixexch.in',
      name: 'phoenixexch',
      dName: 'admin.phoenixexch.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-phoenixexch.css'],
      guestEnable: true,
    },
    {
      domain: 'lucky99exch.com',
      name: 'lucky99exch',
      dName: 'admin.lucky99exch.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-master.css'],
      guestEnable: true,
    },
    {
      domain: 'khelahobe.org',
      name: 'khelahobe',
      dName: 'admin.khelahobe.org',
      ext: '.org',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-khelahobe.css'],
      guestEnable: true,
    },
    {
      domain: 'magicexch.com',
      name: 'magicexch',
      dName: 'admin.magicexch.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-magicexch.css'],
      guestEnable: true,
    },
    {
      domain: 'lotusbook777.co',
      name: 'lotusbook777',
      dName: 'admin.lotusbook777.co',
      ext: '.co',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-lotusbook777.css'],
      guestEnable: true,
    },
    {
      domain: 'mymatch99.com',
      name: 'mymatch99',
      dName: 'admin.mymatch99.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-mymatch99.css'],
      guestEnable: true,
    },
    {
      domain: 'dreamexch99.com',
      name: 'dreamexch99',
      dName: 'admin.dreamexch99.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-dreamexch99.css'],
      guestEnable: true,
    },
    {
      domain: 'spbook88.com',
      name: 'spbook88',
      dName: 'admin.spbook88.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-spbook.css'],
      guestEnable: true,
    },
    {
      domain: 'maharaja999.in',
      name: 'maharaja999',
      dName: 'admin.maharaja999.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-maharaja999.css'],
      guestEnable: true,
    },
    {
      domain: 'shakunee247.com',
      name: 'shakunee247',
      dName: 'admin.shakunee247.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-shakunee247.css'],
      guestEnable: true,
    },
    {
      domain: 'kapildev365.com',
      name: 'kapildev365',
      dName: 'admin.kapildev365.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-kapildev365.css'],
      guestEnable: true,
    },
    {
      domain: 'mjexch.com',
      name: 'mjexch',
      dName: 'admin.mjexch.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-mjexch.css'],
      guestEnable: true,
    },
    {
      domain: 'wold77.com',
      name: 'wold77',
      dName: 'admin.wold77.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-wold77.css'],
      guestEnable: true,
    },
    {
      domain: 'win777exch.in',
      name: 'win777exch',
      dName: 'admin.win777exch.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-win777exch.css'],
      guestEnable: true,
    },
    {
      domain: '11starss.in',
      name: '11starss',
      dName: 'admin.11starss.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-11starss.css'],
      guestEnable: true,
    },
    {
      domain: 'kingsmakers.in',
      name: 'kingsmakers',
      dName: 'admin.kingsmakers.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-kingsmakers.css'],
      guestEnable: true,
    },
    {
      domain: 'redsports999.in',
      name: 'redsports999',
      dName: 'admin.redsports999.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-maharaja999.css'],
      guestEnable: true,
    },
    {
      domain: 'zebraexch7.com',
      name: 'zebraexch',
      dName: 'admin.zebraexch7.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-zebraexch7.css'],
      guestEnable: true,
    },
    {
      domain: 'zebraexch9.com',
      name: 'zebraexch',
      dName: 'admin.zebraexch9.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-zebraexch9.css'],
      guestEnable: true,
    },
    {
      domain: 'pridexexch777.com',
      name: 'pridexexch777',
      dName: 'admin.pridexexch777.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-pride.css'],
      guestEnable: true,
    },
    {
      domain: 'indusbook247.com',
      name: 'indusbook247',
      dName: 'admin.indusbook247.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-indusbook.css'],
      guestEnable: true,
    },
    {
      domain: 'whitepawn777.in',
      name: 'whitepawn777',
      dName: 'admin.whitepawn777.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-whitepawn.css'],
      guestEnable: true,
    },
    {
      domain: 'spexchange.club',
      name: 'spexchange',
      dName: 'admin.spexchange.club',
      ext: '.club',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-master.css'],
      guestEnable: true,
    },
    {
      domain: 'noh555.com',
      name: 'noh555',
      dName: 'admin.noh555.com',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-master.css'],
      guestEnable: true,
    },
    {
      domain: 'shyam77.com',
      name: 'shyam77',
      dName: 'admin.shyam77.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-balajionline777.css'],
      guestEnable: true,
    },
    {
      domain: 'allbet9.com',
      name: 'allbet9',
      dName: 'admin.allbet9.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-magicexch.css'],
      guestEnable: true,
    },
    {
      domain: 'taj247.co',
      name: 'taj247',
      dName: 'admin.taj247.co',
      ext: '.co',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-taj247.css'],
      guestEnable: true,
    },
    {
      domain: 'taj365.co',
      name: 'taj365',
      dName: 'admin.taj365.co',
      ext: '.co',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-taj365.css'],
      guestEnable: true,
    },
    {
      domain: 'dksports555.in',
      name: 'dksports555',
      dName: 'admin.dksports555.in',
      ext: '.in',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-maharaja999.css'],
      guestEnable: true,
    },
    {
      domain: 'parker777.live',
      name: 'parker777',
      dName: 'admin.parker777.live',
      ext: '.live',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-master.css'],
      guestEnable: true,
    },
    {
      domain: 'parker777.pro',
      name: 'parker777',
      dName: 'admin.parker777.pro',
      ext: '.pro',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-master.css'],
      guestEnable: true,
    },
    {
      domain: 'radhe777.io',
      name: 'radhe777',
      dName: 'admin.radhe777.io',
      ext: '.io',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-master.css'],
      guestEnable: true,
    },
    {
      domain: 'mahadev55.com',
      name: 'mahadev55',
      dName: 'admin.mahadev55.com',
      ext: '.com',
      theme: 'blue-body',
      email: '',
      pusher: {
        key: 'c8bbdb36496c73b41567',
        cluster: 'eu'
      },
      themePath: ['assets/css/theme-files/theme-magicexch.css'],
      guestEnable: true,
    },
  ]
};